import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/9zuDIU-6-uw">
    <SEO title="Isaac's Wells - Jesus in Genesis" />
  </Layout>
)

export default SermonPost
